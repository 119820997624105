<template>
  <div class="details">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <button class="modal-default-button" @click="$emit('close')">
                  <img :src="require(`@/assets/images/close.svg`)" alt="" />
                </button>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <figure class="gift">
                  <img
                    class="gift__image"
                    :src="giftItem.image_url"
                    :alt="giftItem.name"
                  />
                  <figcaption class="gift__name">
                    <h6>{{ giftItem.name }}</h6>
                    <p>{{ giftItem.vendor }}</p>
                    <p class="description">
                      {{ giftItem.description }}
                    </p>
                    <div class="gift__contact">
                      <a
                        :href="giftItem.order_url"
                        target="_blank"
                        class="gift__contact-whatsapp"
                      >
                        <!-- <img
                          :src="require(`@/assets/images/whatsapp.svg`)"
                          alt=""
                        /> -->
                        <span>Order Now</span>
                      </a>
                      <a
                        :href="giftItem.instagram"
                        class="gift__contact-instagram"
                        v-if="giftItem.instagram"
                        target="_blank"
                      >
                        <!-- <img
                          :src="require(`@/assets/images/instagram.svg`)"
                          alt=""
                        /> -->
                        <span>More from Vendor</span>
                      </a>
                    </div>
                  </figcaption>
                </figure>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <!-- default footer
                <button class="modal-default-button" @click="$emit('close')">
                  OK
                </button> -->
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "GiftDetails",
  props: ["giftItem"]
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_giftdetails";
</style>
